import React from "react";

const TablePageContainerLayout = ({ children }) => {
  return (
    <div className="py-10 px-20 h-full flex flex-grow-0 flex-col">
      {children}
    </div>
  );
};

export default TablePageContainerLayout;
