import { useFindRecord } from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useDefaultQueryConfig } from "api/apiUtils.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";

export const useFindAuthUser = (id) => {
  const { authToken } = useAuthContext();
  const defaultQueryConfig = useDefaultQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.AUTH_API_BASE_URL,
    authToken,
  );

  return useFindRecord("user", id, jsonApiAdapter, {
    queryConfig: defaultQueryConfig,
  });
};
