import {
  useFindRecord,
  usePopulate,
  useUpdateRecord,
  useInvalidateQueries,
} from "api/apiHooks.js";
import {
  useDefaultQueryConfig,
  useDefaultMutationConfig,
} from "api/apiUtils.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useToast } from "@unite-us/app-components";

export const useContactHqUserOnly = (query) => {
  const { authToken } = useAuthContext();
  const defaultQueryConfig = useDefaultQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );
  const id = query;

  const response = useFindRecord("person", id, jsonApiAdapter, {
    queryConfig: {
      ...defaultQueryConfig,
      enabled: !!query,
      onError: () => {},
    },
  });

  usePopulate("consent", "consent", response.data, jsonApiAdapter);

  return response;
};

export const useRevokeConsent = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useUpdateRecord("consent", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      onSuccess: () => {
        invalidateQueries("consent");
        invalidateQueries("person");
        addToast({
          message: "Consent has been revoked successfully",
          type: "success",
          dataTestId: "revoke-consent-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error revoking consent: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "revoke-consent-error-toast",
        });
      },
      ...mutationConfig,
    },
  });
};
