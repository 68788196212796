import React from "react";
import PropTypes from "prop-types";
import { Button, InputField, SelectField } from "@unite-us/ui";
import { RadioField } from "@unite-us/app-components";
import { Form, Field } from "react-final-form";
import { isRequired } from "utils/validations";
import { useNavigate } from "react-router";
import NavigationHeader from "components/NavigationHeader";

const PayerForm = ({ onSubmit, initialValues, payerId }) => {
  const navigate = useNavigate();
  const isEditing = !!payerId;
  const onCancel = () => {
    if (isEditing) {
      navigate(`/payers/${payerId}`);
    } else {
      navigate("/payers");
    }
  };
  const backRedirection = isEditing ? `/payers/${payerId}` : `/payers`;

  return (
    <div className="flex flex-col items-center justify-center w-full pb-4">
      <NavigationHeader
        backButtonRedirectTo={backRedirection}
        dataTestId="payer-form-navigation-header"
      />
      <div className="flex flex-row justify-start w-2/5 min-w-80 py-3">
        <h1>{isEditing ? "Edit Payer" : "Create New Payer"}</h1>
      </div>

      <div className="border-2 rounded bg-medium-fill-grey p-8 w-2/5 min-w-80">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="name"
                validate={(val) => isRequired(val, "Name is required")}
              >
                {(props) => (
                  <InputField
                    {...props}
                    id="payer-form__name"
                    label="Name"
                    dataTestId="payer-form-name"
                    className="w-full"
                    required
                  />
                )}
              </Field>
              <Field
                name="external_id"
                validate={(val) => isRequired(val, "External ID is required")}
              >
                {(props) => (
                  <InputField
                    {...props}
                    id="payer-form__external-id"
                    label="External ID"
                    dataTestId="payer-form-external-id"
                    className="w-full"
                    required
                  />
                )}
              </Field>
              <Field
                name="payer_wq_interactive"
                validate={(val) =>
                  isRequired(val, "Payer WQ Interactive is required")
                }
              >
                {({ input, meta }) => (
                  <RadioField
                    type="radio"
                    className="flex-wrap w-full px-0"
                    label="Payer WQ Interactive"
                    labelClassName="!tracking-[1px] !uppercase" // Trying to make new RadioField label look like uniteus-ui label
                    id="payer-form__payer-wq-interactive"
                    dataTestId="payer-payer-wq-interactive-radio-group"
                    options={[
                      { label: "No", value: "false" },
                      { label: "Yes", value: "true" },
                    ]}
                    input={{
                      ...input,
                      onChange: (e, disabled) => {
                        input.onChange(e.target.value, disabled);
                      },
                    }}
                    required
                    {...meta}
                  />
                )}
              </Field>
              {isEditing && (
                <Field
                  name="state"
                  validate={(val) => isRequired(val, "Status is required")}
                >
                  {(props) => (
                    <SelectField
                      id="payer-form__status"
                      label="Status"
                      dataTestId="payer-form-status"
                      className="w-full"
                      options={[
                        { label: "Active", value: "active" },
                        { label: "Archived", value: "archived" },
                      ]}
                      searchEnabled={false}
                      clearable={false}
                      {...props}
                    />
                  )}
                </Field>
              )}
              <div className="flex flex-row space-x-2 justify-end">
                <Button
                  data-testid="payer-form-cancel-btn"
                  label="Cancel"
                  onClick={onCancel}
                  className="border-table-border-color w-32"
                />
                <Button
                  type="submit"
                  data-testid="payer-form-submit-btn"
                  label={isEditing ? "Update" : "Create"}
                  className=" bg-action-blue text-white w-32 text-sm font-medium focus:border-focus-blue capitalize"
                  omitDefaultStyles
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

PayerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  payerId: PropTypes.string,
};

PayerForm.defaultProps = {
  initialValues: {},
  payerId: null,
};

export default PayerForm;
