import { datadogLogs } from "@datadog/browser-logs";

const initializeDatadogLogs = () => {
  datadogLogs.init({
    applicationId: window.feConfig.DATADOG_APPLICATION_ID,
    clientToken: window.feConfig.DATADOG_CLIENT_TOKEN,
    site: "ddog-gov.com",
    service: "mission-control",
    env: window.feConfig.ENVIRONMENT,
    version: window.feConfig.IMAGE_TAG || "local",
    sessionSampleRate: 100,
    defaultPrivacyLevel: "mask-user-input",
    forwardConsoleLogs: "all",
    proxy: (options) =>
      `${window.feConfig.DATADOG_PROXY_URL}/${options.path}?${options.parameters}`,
    allowedTracingUrls: [
      /https:\/\/core\.uniteus.*\.com\/.*/,
      /https:\/\/core\.uniteus\.io\/.*/,
      /https:\/\/datadog\.uniteus\.com\/.*/,
      /https:\/\/datadog\.uniteus\.io\/.*/,
    ],
  });
};

export default initializeDatadogLogs;
