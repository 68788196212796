import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { Icon } from "@unite-us/app-components";
import { MissionControlLogo } from "./MissionControlLogo";
import "./NavBar.css";

const pages = [
  {
    id: "users",
    label: "Users",
    route: "/users",
  },
  {
    id: "import-center",
    label: "Import Center",
    route: "/import-center",
  },
  {
    id: "forms",
    label: "Forms",
    route: "/forms",
  },
];

const LeftNav = ({ dataTestId }) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row items-center" data-testid={dataTestId}>
      <div data-testid="uu-logo-icon-container">
        <Icon
          icon="LogoShield"
          icons={{
            icon: MissionControlLogo,
          }}
          color="#FFFFFF"
          className="block h-[48px] w-[124px] ml-1 mr-4"
          onClick={() => navigate("/")}
        />
      </div>
      {pages.map((page) => (
        <NavLink
          key={page.id}
          to={page.route}
          className={({ isActive }) => `navButton ${isActive ? "active" : ""}`}
          style={({ isActive }) => {
            return {
              fontWeight: isActive ? "bold" : "",
              color: "white",
            };
          }}
          data-testid={`${page.id}-nav-link`}
        >
          {page.label}
        </NavLink>
      ))}
    </div>
  );
};

LeftNav.propTypes = {
  dataTestId: PropTypes.string,
};

LeftNav.defaultProps = {
  dataTestId: "",
};

export default LeftNav;
