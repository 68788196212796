import React, { useEffect, useState } from "react";
import PaginatedTable from "components/Tables/PaginatedTable";
import { useForms } from "api/core/formHooks";
import { Button } from "@unite-us/app-components";
import SearchBar from "components/SearchBar/SearchBar";
import TablePageContainerLayout from "layouts/TablePageContainerLayout";
import moment from "moment";
import useLocalStorage from "hooks/useLocalStorage";

const Forms = () => {
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useLocalStorage("formsSearchTerm", "");

  const {
    data: formsReponse,
    isFetching,
    isError,
  } = useForms({ name: searchTerm, state: "published" }, pageSize, pageNumber);
  const { data: forms, paging } = formsReponse || [];

  useEffect(() => {
    setPageNumber(1);
  }, [searchTerm]);

  const handleOnSubmitSearchBar = (data) => {
    setSearchTerm(data?.search);
  };

  const tableHeaders = [{ label: "Name" }, { label: "Last updated" }];
  const tableBody = forms?.map((form) => ({
    rowId: form.id,
    rowData: [
      { data: form.name },
      { data: moment(form.updated_at).format("LL") },
    ],
  }));

  return (
    <TablePageContainerLayout>
      <div data-testid="forms-header">
        <h1 className="w-12/12 flex-row pt-1 pb-2 font-bold text-brand-blue">
          Forms
        </h1>
        <div className="flex pb-2">
          <div className="w-9/12 flex flex-row relative">
            <div className="md:w-3/5">
              <SearchBar
                onSubmit={handleOnSubmitSearchBar}
                dataTestId="forms-search-bar"
                placeholder="Search by Name"
                delay={2000}
                initialValue={searchTerm}
              />
            </div>
          </div>
          <div className="w-3/12 flex justify-end">
            <Button
              id="add-form-button"
              icon={{ name: "Add", color: "text-blue", position: "left" }}
              className={
                "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
              }
              label="Create New Form"
              data-testid="create-form-btn"
              disabled
            />
          </div>
        </div>
      </div>
      <PaginatedTable
        headers={tableHeaders}
        body={tableBody}
        isFetching={isFetching}
        isError={isError}
        errorMessage="Error Fetching Forms"
        emptyTableMessage="No Forms Found"
        pageSize={pageSize}
        pageNumber={pageNumber}
        paging={paging}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        dataTestId="forms-table"
      />
    </TablePageContainerLayout>
  );
};

export default Forms;
