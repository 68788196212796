import { useEffect, useMemo } from "react";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { getUserObjectFromToken } from "components/auth/utils/getUserObjectFromToken";
import { PropTypes } from "prop-types";

const PendoWrapper = ({ children }) => {
  const { authToken } = useAuthContext();
  const { email, userId } = useMemo(() => {
    return getUserObjectFromToken(authToken);
  }, [authToken]);

  useEffect(() => {
    if ((email, userId)) {
      (function (apiKey) {
        (function (p, e, n, d, o) {
          var v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = o._q || [];

          v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              o[m] =
                o[m] ||
                function () {
                  o._q[m === v[0] ? "unshift" : "push"](
                    [m].concat([].slice.call(arguments, 0)),
                  );
                };
            })(v[w]);

          y = e.createElement(n);
          y.async = !0;
          y.src = window.feConfig.PENDO_BASE_URL + apiKey + "/pendo.js";
          z = e.getElementsByTagName(n)[0];
          if (z && z.parentNode) {
            z.parentNode.insertBefore(y, z);
          } else {
            e.head.appendChild(y);
          }
        })(window, document, "script", "pendo");
      })(window.feConfig.PENDO_API_KEY);

      window.pendo.initialize({
        visitor: {
          id: userId,
          email: email,
        },
      });
    }
  }, [userId, email]);

  return <>{children}</>;
};

PendoWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PendoWrapper;
