import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CardRowDivider from "./CardRowDivider";

const CardRow = ({
  capitalize,
  content,
  dataTestId,
  divider,
  title,
  containerClassName,
  titleClassName,
  contentClassName,
  spaceBetweenTitleAndContent,
}) => {
  const spaceClass = classNames({
    "w-full": !content,
    "w-1/8": content && spaceBetweenTitleAndContent === "xs",
    "w-1/6": content && spaceBetweenTitleAndContent === "sm",
    "w-1/3":
      content &&
      (spaceBetweenTitleAndContent === "base" ||
        !["sm", "base", "lg", "xs"].includes(spaceBetweenTitleAndContent)),
    "w-1/2": content && spaceBetweenTitleAndContent === "lg",
  });

  return (
    <div className="flex flex-col">
      <div
        className={`flex flex-row p-3 ${containerClassName}`}
        data-testid={`${dataTestId}-card-row`}
      >
        <div
          className={`${spaceClass} min-w-36 font-black ${titleClassName}`}
          data-testid={`${dataTestId}-card-row-title`}
        >
          {title}
        </div>
        <div
          className={`${capitalize ? "capitalize" : ""} ${contentClassName}`}
          data-testid={`${dataTestId}-card-row-content`}
        >
          {content}
        </div>
      </div>
      {divider && <CardRowDivider dataTestId={dataTestId} />}
    </div>
  );
};

CardRow.propTypes = {
  capitalize: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dataTestId: PropTypes.string,
  divider: PropTypes.bool,
  title: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
  titleClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  // xs - sm - base - lg
  spaceBetweenTitleAndContent: PropTypes.string,
};

CardRow.defaultProps = {
  capitalize: false,
  content: undefined,
  dataTestId: "",
  divider: true,
  containerClassName: "",
  titleClassName: "",
  contentClassName: "",
  spaceBetweenTitleAndContent: "base",
};

export default CardRow;
