import React from "react";
import PropTypes from "prop-types";
import { Button, InputField, SelectField, DateField } from "@unite-us/ui";
import { RadioField } from "@unite-us/app-components";
import { Form, Field } from "react-final-form";
import { isRequired } from "utils/validations";
import { useNavigate } from "react-router";
import moment from "moment";
import { validateStartDate, validateEndDate } from "utils/validations";
import NetworkSelector from "components/NetworkSelector";
import NavigationHeader from "components/NavigationHeader";

const PlanForm = ({ onSubmit, initialValues, payerId, planId }) => {
  const navigate = useNavigate();
  const isEditing = !!planId;
  const startDate =
    moment(initialValues.starts_at, "MM/DD/YYYY").format("MM/DD/YYYY") ||
    moment().format("MM/DD/YYYY");
  const shouldRequireNetwork = !!(
    initialValues.networks && initialValues.networks.length
  );
  const backRedirection = isEditing
    ? `/payers/${payerId}/plans/${planId}`
    : `/payers/${payerId}`;

  const onCancel = () => {
    navigate(backRedirection);
  };

  const planTypes = [
    { label: "Medicare", value: "medicare" },
    { label: "Medicaid", value: "medicaid" },
    { label: "Commercial", value: "commercial" },
    { label: "Social", value: "social" },
    { label: "Tricare", value: "tricare" },
  ];

  const enrollmentOptions = [
    { label: "Required", value: "true" },
    { label: "Not Required", value: "false" },
  ];

  return (
    <div className="flex flex-col items-center justify-center w-full pb-4">
      <NavigationHeader
        backButtonRedirectTo={backRedirection}
        dataTestId="plan-form-navigation-header"
      />
      <div className="flex flex-row justify-start w-2/5 min-w-80 py-3">
        <h1>{isEditing ? "Edit Plan" : "Add New Plan"}</h1>
      </div>

      <div className="border-2 rounded bg-medium-fill-grey p-8 w-2/5 min-w-80">
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, values, form }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="name"
                validate={(val) => isRequired(val, "Name is required")}
              >
                {(props) => (
                  <InputField
                    id="plan-form__name"
                    label="Plan Name"
                    dataTestId="plan-form-name"
                    className="w-full !pb-0"
                    required
                    {...props}
                  />
                )}
              </Field>
              <Field
                name="plan_type"
                validate={(val) => isRequired(val, "Plan Type is required")}
              >
                {(props) => (
                  <SelectField
                    id="plan-form__plan-type"
                    label="Plan Type"
                    dataTestId="plan-form-plan-type"
                    className="w-full"
                    options={planTypes}
                    placeholder="Select Plan Type"
                    searchEnabled={false}
                    clearable={false}
                    required
                    {...props}
                  />
                )}
              </Field>
              <Field name="external_id">
                {(props) => (
                  <InputField
                    id="plan-form__external-id"
                    label="External ID"
                    dataTestId="plan-form-external-id"
                    className="w-full"
                    {...props}
                  />
                )}
              </Field>
              {values?.plan_type === "social" && (
                <>
                  <Field
                    name="networks"
                    validate={(val) => {
                      if (shouldRequireNetwork) {
                        return isRequired(
                          val,
                          "Should maintain at least one network relationship",
                        );
                      }
                      return null;
                    }}
                  >
                    {(props) => (
                      <NetworkSelector
                        className="md:col-span-6 xl:col-span-6 px-0"
                        required={shouldRequireNetwork}
                        changeValue={form.change}
                        {...props}
                      />
                    )}
                  </Field>
                  <div className="md:col-span-6 xl:col-span-6 px-0 flex" />
                </>
              )}
              <Field name="region">
                {(props) => (
                  <InputField
                    id="plan-form__region"
                    label="Region"
                    dataTestId="plan-form-name"
                    className="w-full"
                    {...props}
                  />
                )}
              </Field>
              <div className="flex flex-row w-full">
                <Field
                  name="starts_at"
                  validate={(value) =>
                    validateStartDate(value, values.ends_at, startDate)
                  }
                >
                  {(props) => (
                    <DateField
                      className="w-1/2 pl-0 pr-4"
                      id="plan-form__starts-at"
                      dataTestId="plan-form-starts-at"
                      label="Start Date"
                      minDate={moment(startDate, "MM/DD/YYYY")
                        .subtract(1, "day") // Subtracting 1 day since DateField starts one day after minDate
                        .format("MM/DD/YYYY")}
                      {...(values.ends_at && {
                        maxDate: moment(values.ends_at, "MM/DD/YYYY")
                          .subtract(1, "day") // Subtracting 1 day since the plan can't start and end the same day
                          .format("MM/DD/YYYY"),
                      })}
                      valueFormat="MM/DD/YYYY"
                      {...props}
                    />
                  )}
                </Field>
                <Field
                  name="ends_at"
                  validate={(value) => validateEndDate(value, values.starts_at)}
                >
                  {(props) => (
                    <DateField
                      className="w-1/2 pl-4 pr-0"
                      id="plan-form__ends-at"
                      dataTestId="plan-form-ends-at"
                      label="End Date"
                      minDate={
                        values.starts_at || moment().format("MM/DD/YYYY")
                      }
                      valueFormat="MM/DD/YYYY"
                      {...props}
                    />
                  )}
                </Field>
              </div>
              {values?.plan_type === "social" && (
                <>
                  <Field name="enrollment_required">
                    {({ input, meta }) => (
                      <RadioField
                        type="radio"
                        className="flex-wrap w-full px-0"
                        id="plan-form__enrollment-required"
                        label="Enrollment"
                        labelClassName="!text-xs !text-[#506784] tracking-[1px] !leading-[18px] font-heavy-font !uppercase" // Trying to make new RadioField label look like uniteus-ui label
                        options={enrollmentOptions}
                        dataTestId="plan-enrollment-radio-group"
                        input={{
                          ...input,
                          onChange: (e, disabled) => {
                            input.onChange(e.target.value, disabled);
                          },
                        }}
                        meta={meta}
                      />
                    )}
                  </Field>
                  <Field name="coverage_period">
                    {(props) => (
                      <InputField
                        className="w-full"
                        id="plan-form__coverage-period"
                        label="Coverage Period"
                        type="number"
                        {...props}
                      />
                    )}
                  </Field>
                </>
              )}
              <div className="flex flex-row space-x-2 justify-end">
                <Button
                  data-testid="plan-form-cancel-btn"
                  label="Cancel"
                  onClick={onCancel}
                  className="border-table-border-color w-32"
                />
                <Button
                  type="submit"
                  data-testid="plan-form-submit-btn"
                  label={isEditing ? "Update" : "Create"}
                  className=" bg-action-blue text-white w-32 text-sm font-medium focus:border-focus-blue capitalize"
                  omitDefaultStyles
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

PlanForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  payerId: PropTypes.string.isRequired,
  planId: PropTypes.string,
};

PlanForm.defaultProps = {
  initialValues: {},
  planId: null,
};

export default PlanForm;
