import { useDefaultQueryConfig } from "api/apiUtils.js";
import { useFindAll } from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";

export const useAllRoles = () => {
  const { authToken } = useAuthContext();
  const defaultQueryConfig = useDefaultQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFindAll("role", jsonApiAdapter, {
    queryConfig: defaultQueryConfig,
  });
};
