import {
  useDefaultPagingQueryConfig,
  useDefaultQueryConfig,
} from "api/apiUtils.js";
import { useFind, useFindRecord } from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";

export const useEmployees = ({
  query = "",
  filters = {},
  include = "",
  pageSize = 15,
  pageNumber = 1,
  sort = "",
  queryConfig = {},
}) => {
  const { authToken } = useAuthContext();
  const defaultPagingQueryConfig = useDefaultPagingQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind(
    "employee",
    {
      query: query,
      ...filters,
    },
    jsonApiAdapter,
    {
      queryConfig: {
        enabled: filters.user !== "",
        ...defaultPagingQueryConfig,
        ...queryConfig,
      },
      page: { number: pageNumber, size: pageSize },
      sort: sort,
      include: include,
    },
  );
};

export const useFindEmployee = (id) => {
  const { authToken } = useAuthContext();
  const defaultQueryConfig = useDefaultQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFindRecord("employee", id, jsonApiAdapter, {
    queryConfig: defaultQueryConfig,
  });
};
