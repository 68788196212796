import React from "react";
import PropTypes from "prop-types";

export const UserSSODisplay = ({
  samlName,
  scimName,
  provisionedOn,
  provisionUpdatedOn,
}) => (
  <div className="flex flex-col">
    <div className="flex">
      <div className="w-1/10 pt-1" style={{ fontWeight: 900, color: "black" }}>
        Auth Type
      </div>
      <div className="w-1/3 pt-1" style={{ fontWeight: 900, color: "black" }}>
        Configured
      </div>
      <div className="w-1/4 pt-1" style={{ fontWeight: 900, color: "black" }}>
        Provisioned On
      </div>
      <div className="w-1/4 pt-1" style={{ fontWeight: 900, color: "black" }}>
        Provision Updated On
      </div>
    </div>
    <hr className="col-span-4 border-medium-border-blue" />
    <div className="flex bg-white w-full">
      <div data-testid="saml" className="w-1/10 pt-3">
        SAML
      </div>
      <div data-testid="samlName" className="w-1/3 pt-3">
        {samlName || "None"}
      </div>
      <div data-testid="samlProvisionedOn" className="w-1/4 pt-3">
        {samlName ? provisionedOn : ""}
      </div>
      <div data-testid="samlProvisionUpdatedOn" className="w-1/4 pt-3">
        {samlName ? provisionUpdatedOn : ""}
      </div>
    </div>
    <div className="flex bg-white">
      <div data-testid="scim" className="w-1/10 py-3">
        SCIM
      </div>
      <div data-testid="scimName" className="w-1/3 py-3">
        {scimName || "None"}
      </div>
      <div data-testid="scimProvisionedOn" className="w-1/4 py-3">
        {scimName ? provisionedOn : ""}
      </div>
      <div data-testid="scimProvisionUpdatedOn" className="w-1/4 py-3">
        {scimName ? provisionUpdatedOn : ""}
      </div>
    </div>
  </div>
);

UserSSODisplay.propTypes = {
  samlName: PropTypes.string,
  scimName: PropTypes.string,
  provisionedOn: PropTypes.string,
  provisionUpdatedOn: PropTypes.string,
};

UserSSODisplay.defaultProps = {
  samlName: "",
  scimName: "",
  provisionedOn: "",
  provisionUpdatedOn: "",
};
