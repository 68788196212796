import { useFindRecord } from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useDefaultQueryConfig } from "api/apiUtils.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";

export const useFindSamlConfiguration = (id) => {
  const { authToken } = useAuthContext();
  const defaultQueryConfig = useDefaultQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.AUTH_API_BASE_URL,
    authToken,
  );

  return useFindRecord("saml_configuration", id, jsonApiAdapter, {
    queryConfig: {
      ...defaultQueryConfig,
      enabled: !!id,
    },
  });
};
