import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@unite-us/app-components";
import DialogV2 from "components/DialogV2/DialogV2";
import MetafieldForm from "./MetafieldForm";

const EditMetafields = ({
  metafields,
  modalRef,
  confirmationHandler,
  isAttachment,
}) => {
  const [newMetafields, setNewMetafields] = useState([...metafields]);
  const cancelHandler = () => {
    setNewMetafields([...metafields]);
    modalRef.current.closeDialog();
  };

  const setNewMetafield = (editedMetafield, index) => {
    const editedMetafields = [...newMetafields];
    editedMetafields[index] = editedMetafield;

    setNewMetafields(editedMetafields);
  };

  const addMetafield = () => {
    const updatedMetafields = [...newMetafields];
    updatedMetafields[updatedMetafields.length] = isAttachment
      ? { label: "", type: "file", field: "", validations: { required: false } }
      : {};
    setNewMetafields(updatedMetafields);
  };

  return (
    <DialogV2
      cancelHandler={cancelHandler}
      confirmationHandler={() => confirmationHandler(newMetafields)}
      confirmLabel="Save"
      ref={modalRef}
      title={`Add/Edit ${isAttachment ? "Attachment" : "Custom Field"}`}
      vertical
      dialogDescriptionClass={"flex-auto overflow-y-auto bg-medium-fill-blue"}
    >
      {newMetafields.map((metafield, index) => (
        <MetafieldForm
          key={`metafield-form-${index}`}
          metafield={metafield}
          setMetafield={setNewMetafield}
          index={index}
          isAttachment={isAttachment}
        />
      ))}
      <Button
        id="add-metafield-button"
        icon={{ name: "Add", color: "text-blue", position: "left" }}
        className="py-0 px-4 text-sm !text-text-blue rounded border solid border-light-border-blue bg-white normal-case h-9"
        label={`Add ${isAttachment ? "Attachment" : "Custom Field"}`}
        onClick={() => addMetafield()}
      />
    </DialogV2>
  );
};

EditMetafields.propTypes = {
  metafields: PropTypes.array.isRequired,
  modalRef: PropTypes.object.isRequired,
  confirmationHandler: PropTypes.func.isRequired,
  isAttachment: PropTypes.bool,
};

EditMetafields.defaultProps = {
  isAttachment: false,
};

export default EditMetafields;
