export const constructPayerInitialFormValues = (payer) => {
  const initialValues = { ...payer };
  const { payer_wq_interactive } = payer;

  if ([true, false].includes(payer_wq_interactive)) {
    initialValues.payer_wq_interactive = payer_wq_interactive.toString();
  }

  return initialValues;
};
