import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";

const ErrorBoundaryWithRouter = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ErrorBoundary location={location} navigate={navigate}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWithRouter;
