export const constructPayerFormPayload = (payer) => {
  const { payer_wq_interactive } = payer;

  const data = {
    ...payer,
    payer_wq_interactive: payer_wq_interactive === "true",
  };

  return data;
};
