import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FilterSingle, Filter } from "@unite-us/client-utils";
import { fetchOrganization } from "api/fetch/fetchOrganizations";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import {
  formatRoleFilterOptions,
  formatOrganizationFilterOptions,
  getNewFiltersAndOrgObjectsByOrg,
  getNewFiltersByRole,
} from "../utils/userTableFiltersUtils";

const fetchOrgs = async (
  { authToken, search, setOrganizations = () => {} },
  dispatch,
) => {
  const { response: orgsResponse } = await fetchOrganization({
    authToken: authToken,
    filters: { name: search },
    page: 1,
    size: 50,
  })(dispatch);
  const orgs = orgsResponse.data.data || [];
  const formattedOrgs = orgs.map((org) => ({
    label: org.name,
    value: org.id,
  }));
  setOrganizations(formattedOrgs);

  return formattedOrgs;
};

const UsersTableFilters = ({
  filters,
  setFilters,
  filteredRoles,
  isFetchingRoles,
}) => {
  const { authToken } = useAuthContext();
  const [organizations, setOrganizations] = useState([]);
  const [orgObjects, setOrgObjects] = useState(filters.providerObjects || []);
  const [isFetchingOrgs, setIsFetchingOrgs] = useState(true);
  const [errorFetchingOrgs, setErrorFetchingOrgs] = useState(false);
  const filtersClassName = "w-1/5 pr-2 pt-1";

  const primaryRolesKeys = filteredRoles.primaryRoles.map((role) => role.key);

  const featureRolesKeys = filteredRoles.featureRoles.map((role) => role.key);

  const handleRolesChanges = (value, type) => {
    setFilters((prevFilters) => {
      const newFilters = getNewFiltersByRole(
        value,
        type,
        prevFilters,
        featureRolesKeys,
        primaryRolesKeys,
      );
      return newFilters;
    });
  };

  const handleStatusChange = (target) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      state: target.value,
    }));
  };

  const handleOrgChange = (target) => {
    const [newFilters, newOrgObjects] = getNewFiltersAndOrgObjectsByOrg(
      target,
      filters,
      orgObjects,
      organizations,
    );
    setFilters(newFilters);
    setOrgObjects(newOrgObjects);
  };

  const asyncSearch = (search) =>
    fetchOrgs({
      authToken,
      search,
      setOrganizations,
    });

  useEffect(() => {
    fetchOrgs({ authToken: authToken, search: "" })
      .then((orgs) => {
        setOrganizations(orgs);
        setIsFetchingOrgs(false);
      })
      .catch(() => {
        setErrorFetchingOrgs(true);
      });
  }, [authToken]);

  return (
    <div className="flex justify-start flex-wrap w-full pb-2 [&_button>svg]:-translate-y-1.5">
      <FilterSingle
        key="user-status-filter-key"
        uniqIdPrefx="user-status-filter"
        name="user-status"
        className={filtersClassName}
        dataTestId="user-status-filter"
        value={filters.state || ""}
        options={[
          { label: "All User Status", value: "" },
          { label: "Active", value: "active" },
          { label: "Inactive", value: "inactive" },
        ]}
        onFilterChange={handleStatusChange}
        filterKey="userStatusFilter"
      />
      <Filter
        key="primary-role-filter-key"
        id="primary-role-filter"
        name={isFetchingRoles ? "Fetching Roles..." : "Primary Role"}
        dataTestId="primary-role-filter"
        options={
          isFetchingRoles
            ? []
            : formatRoleFilterOptions(
                filteredRoles.primaryRoles,
                filters["roles.key"]?.split(","),
              )
        }
        searchPlaceholder="Primary Role"
        className={`${filtersClassName} ${isFetchingRoles ? "opacity-50 pointer-events-none" : ""}`}
        onFiltersChange={(values) => handleRolesChanges(values, "primary")}
        hideSelectAll
        filterKey="primaryRoleFilter"
      />
      <Filter
        key="feature-role-filter-key"
        id="feature-role-filter"
        name={isFetchingRoles ? "Fetching Roles..." : "Feature Role"}
        dataTestId="feature-role-filter"
        options={
          isFetchingRoles
            ? []
            : formatRoleFilterOptions(
                filteredRoles.featureRoles,
                filters["roles.key"]?.split(","),
              )
        }
        searchPlaceholder="Feature Role"
        className={`${filtersClassName} ${isFetchingRoles ? "opacity-50 pointer-events-none" : ""}`}
        onFiltersChange={(values) => handleRolesChanges(values, "feature")}
        hideSelectAll
        filterKey="featureRoleFilter"
      />
      <Filter
        key="organization-filter-key"
        id="organization-filter"
        asyncLoadingText="Fetching Organizations..."
        asyncSearch={asyncSearch}
        name={
          errorFetchingOrgs
            ? "Error Fetching Orgs"
            : isFetchingOrgs
              ? "Fetching Organizations..."
              : "Organization"
        }
        pluralName="Organizations"
        filterKey="organizationsFilter"
        options={formatOrganizationFilterOptions(
          organizations,
          filters.provider?.split(","),
          orgObjects,
        )}
        onFiltersChange={handleOrgChange}
        searchPlaceholder="Search for an organization"
        className={`${filtersClassName} ${isFetchingOrgs || errorFetchingOrgs ? "opacity-50 pointer-events-none" : ""}`}
        dataTestId="organization-filter"
        hideSelectAll={true}
      />
    </div>
  );
};

UsersTableFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  filteredRoles: PropTypes.object.isRequired,
  isFetchingRoles: PropTypes.bool.isRequired,
};

export default UsersTableFilters;
