import React from "react";
import PropTypes from "prop-types";
import NavigationHeader from "components/NavigationHeader";
import { Tab, Tabs } from "@unite-us/ui";
import { Button, Badge } from "@unite-us/app-components";
import { stateBgColor, textColorForStyleProp } from "utils/stateBgColor";
import { capitalize } from "lodash";
import { useNavigate } from "react-router";

const PayerHeader = ({ handleTabChange, location, payer, crumbs }) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col items-start justify-center w-full border-b border-medium-border-blue"
      data-testid="payer-header-container"
    >
      <NavigationHeader
        crumbsData={crumbs}
        backButtonRedirectTo={`/payers`}
        dataTestId="payer-header-breadcrumbs"
      />

      <div className="px-20 w-full mt-4">
        <div className="flex flex-col">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row items-center">
              <h1 className="text-2xl">{payer.name}</h1>
              <Badge
                className={`!leading-4 !text-sm px-4 mx-2 ${stateBgColor(payer.state)}`}
                text={capitalize(payer.state)}
                color=""
                style={textColorForStyleProp(payer.state)}
              />
            </div>
            <Button
              id="edit-payer-button"
              icon={{ name: "Edit", color: "text-blue", position: "left" }}
              className={
                "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
              }
              label="Edit Payer"
              data-testid="edit-payer-button"
              onClick={() => navigate(`/payers/${payer.id}/edit`)}
            />
          </div>

          <div className="inline-block mt-2">
            <span style={{ fontWeight: 900 }} className="text-base">
              External ID:
            </span>
            <span className="text-sm font-normal mx-2">
              {payer.external_id}
            </span>
          </div>

          <div className="inline-block mt-2">
            <span style={{ fontWeight: 900 }} className="text-base">
              Payer WQ Interactive:
            </span>
            <span className="text-sm font-normal mx-2">
              {payer.payer_wq_interactive ? "Yes" : "No"}
            </span>
          </div>
        </div>

        <Tabs
          id="payer-tabs"
          className="mt-6"
          onChange={handleTabChange}
          value={location}
        >
          <Tab
            id="payer-plans-tab"
            label="Plans"
            value="plans"
            dataTestId="plans-tab"
          />
        </Tabs>
      </div>
    </div>
  );
};

PayerHeader.prototype = {
  handleTabChange: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  payer: PropTypes.string.isRequired,
  crumbs: PropTypes.array.isRequired,
};

export default PayerHeader;
