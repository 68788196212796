import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "components/NavBar/index.jsx";

export const DefaultLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <NavBar />
      <div className="flex-1 overflow-y-auto pt-[60px]">
        {children ? children : <Outlet />}
      </div>
    </div>
  );
};
