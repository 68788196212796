import Cookies from "js-cookie";

export const submitLogoutForm = (cookieSessionKey) => {
  const form = document.getElementById("logout-auth-form");

  Cookies.remove(cookieSessionKey);
  sessionStorage.clear();
  localStorage.clear();

  if (form) {
    form.submit();
  }
};
