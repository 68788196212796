import {
  useFind,
  useCreateRecord,
  useUpdateRecord,
  useInvalidateQueries,
} from "api/apiHooks.js";
import {
  useDefaultPagingQueryConfig,
  useDefaultMutationConfig,
} from "api/apiUtils.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { useToast } from "@unite-us/app-components";

export const useFormUsages = (filters, pageSize, pageNumber, sort) => {
  const { authToken } = useAuthContext();
  const defaultPagingQueryConfig = useDefaultPagingQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind("form_usage", filters, jsonApiAdapter, {
    queryConfig: { ...defaultPagingQueryConfig, enabled: filters.form !== "" },
    page: { number: pageNumber || 1, size: pageSize || 15 },
    sort: sort || "",
  });
};

export const useCreateFormUsage = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useCreateRecord("form_usage", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      ...mutationConfig,
      onSuccess: () => {
        invalidateQueries("form_usage");
        invalidateQueries("form");
        addToast({
          message: "Enrollment form successfully added to plan",
          type: "success",
          dataTestId: "create-form-usage-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error adding enrollment form to plan: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "create-form-usage-error-toast",
        });
      },
    },
  });
};

export const useUpdateFormUsage = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useUpdateRecord("form_usage", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      ...mutationConfig,
      onSuccess: () => {
        invalidateQueries("form_usage");
        invalidateQueries("form");
        addToast({
          message: "Enrollment form successfully replaced",
          type: "success",
          dataTestId: "update-form-usage-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error replacing enrollment form to plan: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "update-form-usage-error-toast",
        });
      },
    },
  });
};
