import { useToast } from "@unite-us/app-components";

export const flattenDataAndThrowErrors = (response) => {
  if (response.name === "Error") {
    throw response;
  }
  return response?.data?.data;
};

export const flattenResponseAndThrowErrors = (response) => {
  if (response.name === "Error") {
    throw response;
  }
  return response?.data;
};

export const useDefaultQueryConfig = () => {
  const { addToast } = useToast();
  return {
    select: flattenDataAndThrowErrors,
    onError: () =>
      addToast({
        message: "There was an error. Please contact support.",
        type: "error",
      }), // 1 hour stale time
    staleTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
  };
};

export const useDefaultMutationConfig = () => {
  const { addToast } = useToast();
  return {
    onError: () =>
      addToast({
        message: "There was an error. Please contact support.",
        type: "error",
      }),
  };
};

export const useDefaultPagingQueryConfig = () => {
  const { addToast } = useToast();
  return {
    select: flattenResponseAndThrowErrors,
    onError: () =>
      addToast({
        message: "There was an error. Please contact support.",
        type: "error",
      }),
    // 1 hour stale time
    staleTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
  };
};
