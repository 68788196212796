import { intersection, filter, isEqual, uniqWith } from "lodash";

export const getNewFiltersByRole = (
  selectedRoles,
  type,
  filters,
  featureRolesKeys,
  primaryRolesKeys,
) => {
  const currentRoles = filters["roles.key"]?.split(",") || [];
  const isPrimary = type === "primary";
  const currentOtherRoles = isPrimary
    ? intersection(currentRoles, featureRolesKeys)
    : intersection(currentRoles, primaryRolesKeys);
  const newRoles = [...selectedRoles, ...currentOtherRoles];
  return { ...filters, "roles.key": newRoles.join(",") };
};

export const getNewFiltersAndOrgObjectsByOrg = (
  selectedOrgs,
  filters,
  orgObjects,
  organizations,
) => {
  // If no organizations are selected, clear the provider and providerObjects in filters
  if (selectedOrgs.length === 0) {
    return [
      {
        ...filters,
        provider: "",
        providerObjects: [],
      },
      [],
    ];
  } else {
    // Get the current organizations from the filters
    const currentOrgs = filters.provider?.split(",") || [];
    // Filter the current organization objects based on the current organizations
    const currentOrgObjects = filter(orgObjects, (org) =>
      currentOrgs.includes(org.value),
    );
    // Filter the new organization objects based on the selected organizations
    const orgObject = filter(organizations, (org) =>
      selectedOrgs.includes(org.value),
    );
    // Combine the current and new organization objects, removing duplicates
    const newOrgObjects = uniqWith(
      currentOrgObjects.concat(orgObject),
      isEqual,
    );

    // Return the updated filters and organization objects
    return [
      {
        ...filters,
        provider: selectedOrgs.join(","),
        providerObjects: newOrgObjects,
      },
      newOrgObjects,
    ];
  }
};

export const formatRoleFilterOptions = (roles, selectedRoles) => {
  roles.sort((a, b) => a.name.localeCompare(b.name));

  return roles.map((role) => ({
    label: role.name,
    value: role.key,
    initial: selectedRoles?.includes(role.key),
  }));
};

export const formatOrganizationFilterOptions = (
  organizations,
  selectedOrgs,
  orgObjects,
) => {
  const orgsSet = new Set();
  const orgs = [];

  [...orgObjects, ...organizations].forEach((org) => {
    const key = `${org.value}-${org.label}`;
    if (!orgsSet.has(key)) {
      orgsSet.add(key);
      orgs.push({
        ...org,
        initial: selectedOrgs?.includes(org.value),
      });
    }
  });

  return orgs;
};
