import { useDefaultPagingQueryConfig } from "api/apiUtils.js";
import { useFind } from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";

export const useNetworks = ({
  filters = {},
  include = "",
  pageSize = 15,
  pageNumber = 1,
  sort = "",
}) => {
  const { authToken } = useAuthContext();
  const defaultPagingQueryConfig = useDefaultPagingQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind(
    "network",
    {
      ...filters,
    },
    jsonApiAdapter,
    {
      queryConfig: {
        enabled: filters.id !== "" && filters.name !== "",
        ...defaultPagingQueryConfig,
      },
      page: { number: pageNumber, size: pageSize },
      sort: sort,
      include: include,
    },
  );
};
